body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #185d7fe9; */
}
.flex{
  display: flex;
  justify-content: space-between;
}
.row{
  flex-direction: row;
}
.column{
  flex-direction: column;
}
* {
  box-sizing: border-box;
}



.drobdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  background: #f1f1f1;
  min-width: 140px;
  position: absolute;
  border-radius: 5px;
  top: 90%;
  z-index: 1;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0,.5);
  transition: .5s;
}

.dropdown-content .navLink {
  display: flex;
  flex-direction: column;
  color: #000;
  text-decoration: none;
  padding: 12px;
  font-size: 18px;
  transition: .5s;
}
.dropdown-content :nth-child(1) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

}


.dropdown:hover .dropdown-content {
  display: block;
  transition: .5s;
  border-radius: 5px;
}



